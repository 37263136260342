html,
body,
#root {
  height: 100%;
}

html {
  font-size: 10px;
}

@font-face {
  font-family: "New York Extra Large";
  font-style: normal;
  src: url("./assets/fonts/NewYorkExtraLargeBold.woff") format("woff");
}
